var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('item-edit-view',{staticClass:"request-form-editing",attrs:{"title":"Форма","loading":_vm.loading},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_vm._v(" Сохранить ")])]},proxy:true}],null,true)},[(_vm.item)?_c('v-card',[_c('v-card-text',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Название","name":"title","error-messages":errors},model:{value:(_vm.item.title),callback:function ($$v) {_vm.$set(_vm.item, "title", $$v)},expression:"item.title"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Код","name":"code","error-messages":errors},model:{value:(_vm.item.code),callback:function ($$v) {_vm.$set(_vm.item, "code", $$v)},expression:"item.code"}})]}}],null,true)}),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":{ required: !_vm.item.useParentChannelId }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"channelId",attrs:{"dense":"","outlined":"","label":"Номер группы","name":"channelId","error-messages":errors,"disabled":_vm.item.useParentChannelId},model:{value:(_vm.item.channelId),callback:function ($$v) {_vm.$set(_vm.item, "channelId", $$v)},expression:"item.channelId"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"Использовать Id группы родительской сущности","name":"useParentChannelId","error-messages":errors},on:{"change":_vm.onUseParentChannelChanged},model:{value:(_vm.item.useParentChannelId),callback:function ($$v) {_vm.$set(_vm.item, "useParentChannelId", $$v)},expression:"item.useParentChannelId"}})]}}],null,true)})],1)],1),_c('v-textarea',{attrs:{"dense":"","outlined":"","label":"Комментарий","name":"comment"},model:{value:(_vm.item.comment),callback:function ($$v) {_vm.$set(_vm.item, "comment", $$v)},expression:"item.comment"}})],1)],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }