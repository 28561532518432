<template>
  <validation-observer v-slot="{ handleSubmit }">
    <item-edit-view
      class="request-form-editing"
      title="Форма"
      :loading="loading"
    >
      <template v-slot:right>
        <v-btn color="success" @click="handleSubmit(save)">
          Сохранить
        </v-btn>
      </template>

      <v-card v-if="item">
        <v-card-text>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              dense
              outlined
              label="Название"
              name="title"
              v-model="item.title"
              :error-messages="errors"
            />
          </validation-provider>

          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              dense
              outlined
              label="Код"
              name="code"
              v-model="item.code"
              :error-messages="errors"
            />
          </validation-provider>

          <v-row>
            <v-col>
              <validation-provider
                :rules="{ required: !item.useParentChannelId }"
                v-slot="{ errors }"
              >
                <v-text-field
                  dense
                  ref="channelId"
                  outlined
                  label="Номер группы"
                  name="channelId"
                  v-model="item.channelId"
                  :error-messages="errors"
                  :disabled="item.useParentChannelId"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider rules="required" v-slot="{ errors }">
                <v-checkbox
                  @change="onUseParentChannelChanged"
                  label="Использовать Id группы родительской сущности"
                  name="useParentChannelId"
                  v-model="item.useParentChannelId"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-textarea
            dense
            outlined
            label="Комментарий"
            name="comment"
            v-model="item.comment"
          />

        </v-card-text>
      </v-card>
    </item-edit-view>
  </validation-observer>
</template>

<script>
import requestFormService from '@/services/request-form'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'

export default {
  mixins: [createOrUpdateViewMixin(requestFormService)],
  methods: {
    onUseParentChannelChanged (enabled) {
      if (enabled) {
        this.item.channelId = null
      } else {
        this.$nextTick(() => {
          this.$refs.channelId.focus()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
